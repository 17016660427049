import React from "react"
import useTranslations from "../../utils/useTranslations"
import Logo from "../../components/logo"
import Button from "../../components/button"
import backgrounds from "../../components/backgrounds"
import LocalizedLink from "../../components/localizedLink"
import { ANDROID_LINK_FREE, getAndroidLink } from "../../utils/getAndroidLink";

const remoteLink = "https://remote.utorrent.com/"

class utorrentRemote extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      androidLinkFree: ANDROID_LINK_FREE,
    };
  }

  componentDidMount(){
    this.setState({
      androidLinkFree: getAndroidLink('free'),
    })
  }

  render(){

    const { text, pageName, unlocalizedPath } = this.props.pageContext
    const t = useTranslations(text)
    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

    return (
      <>

        <div className="pt-md-2 background-noise-light" styles={backgrounds.lightBg}>

          <div className="container text-dark py-2">

            <div className="d-flex text-center mt-4 my-sm-4 justify-content-center">
              <div className="col-md-12 mt-md-4 order-md-1 px-md-0 d-flex flex-column">
                <Logo secondaryColor="black" tag="h1" productName="Remote" className="mb-4"/>
                <h2 className="text-size-24 text-spacing-1 font-weight-normal mx-auto mt-2 mb-5 remote-header" style={{maxWidth: '420px'}}>{t('Secure access to µTorrent Classic for Windows or Mac. On any device.')}</h2>
                <p className="m-0">{t(`Already a Remote User?`)} </p>
                <p className="m-0">{t(`Login from your web browser.`)} </p>
        				<div>
        					<Button  href={remoteLink} id="remote-hero-cta-login" className="btn-primary text-spacing-1 my-5 button-filled text-spacing-1">{t(`Login`)}</Button>
        				</div>

                <p className="m-0 mb-2">{t(`Connect with µTorrent Android.`)}</p>
        				<a href={this.state.androidLinkFree} id="product-android-free" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
        					<img className="google-play-button img-fluid" src={googlePlayButton} alt="µTorrent for Android Google Play Store badge"></img>
        				</a>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden remote-content">
          <div className="container my-5">
          	<div className="pb-5">
          		<p className="m-0 my-4 text-primary text-size-24 font-weight-bold">{t(`µTorrent Remote Signup`)}</p>
          		<p className="m-0 product-faq-answer">{t(`µTorrent Remote is a feature that allows you to manage µTorrent Classic for Windows or Mac on your home computer. Once the setup is complete, you can quickly add, remove, start and stop downloads on your home computer from wherever you are, and from any device. `)} </p>
          		<br/>
          		<p className="m-0 product-faq-answer">{t(`In order to keep your information private, we do not allow you to sign up on our website. Instead, you must sign up for an account from µTorrent Android or from a Web Browser. Both methods are outlined below.`)} </p>
          	</div>

          	<div className="pb-5">
          		<p className="m-0 my-4 text-primary text-size-24 font-weight-bold">
      					{t(`Setup from µTorrent Android`)} 
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Step #1`)} 
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Install µTorrent Android, then open the app on your Android device. Next, open µTorrent Classic on your Windows home computer (this method is not compatible with Mac), then make sure both your Android and Windows devices are on the same wifi network. Tap on the computer icon in the top right corner of µTorrent for Android. A window will pop up with a 4-digit PIN code.`)} 
          		</p>
          		<br/>
          		<p className="m-0 product-faq-answer">
      					 {t(`Step #2`)} 
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Open µTorrent Classic for Windows on your home computer and find the popup window labeled ‘Connect to your device’, then enter the 4-digit PIN code that was provided to you on your Android device. Now you are paired and ready to add, pause, resume, and delete torrents from anywhere! Please remember that for the remote connection to work, you must leave your home computer on and the µTorrent desktop app running.`)} 
          		</p>
          		<br/>
          		<p className="m-0 product-faq-answer">
      					 {t(`Note: Follow these links if you need to install µTorrent Classic for Windows or µTorrent Android.`, {"µTorrent Classic":<LocalizedLink to="/desktop/">{t(`µTorrent Classic`)}</LocalizedLink>, "µTorrent Android": <LocalizedLink to="/mobile/">{t(`µTorrent Android`)}</LocalizedLink>})} 
          		</p>
          	</div>

      			<div className="pb-5">
          		<p className="m-0 my-4 text-primary text-size-24 font-weight-bold">
      					{t(`Setup from a Web Browser`)}
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Step #1`)} 
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Open µTorrent Classic (Windows or Mac) and go to Options > Preferences > Remote.`)} 
          		</p>
          		<br/>
          		<p className="m-0 product-faq-answer">
      					 {t(`Step #2`)} 
          		</p>
          		<p className="m-0 product-faq-answer">
      					 {t(`Select 'Enable µTorrent Remote Access', then choose a computer name (the name can be anything) and password. Click the 'Apply' or ‘Sign in’ button. When you see the status as 'Accessible', you can now access µTorrent Classic from a browser at https://remote.utorrent.com by using the same computer name and password. Note that for you to remotely connect to µTorrent Classic on your home computer, your home computer must remain on and µTorrent Classic must be running.`)} 
          		</p>
          	</div>

          </div>
        </div>


	  </>


    )

  }

}

export default utorrentRemote
